import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import _ from "lodash";

import { Box, Typography } from "@mui/material";
import { UiShellHeader } from "./UiShellHeader";
import { UiShellFooter } from "./UiShellFooter";
import { StyledUiShell } from "./UiShell.styles";

import { setUsername } from "../../store/reducers/auth.reducer";

interface UiShellProps {
  children: React.ReactNode;
}

export const UiShell: React.FC<UiShellProps> = ({ children }) => {
  const { username } = useSelector((state: any) => state.authReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    const getCurrentAuthenticatedUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });

        dispatch(setUsername(currentUser.attributes.email));
      } catch (error) {
        console.log(error);
      }
    };

    getCurrentAuthenticatedUser();
  }, [username]);

  return (
    <StyledUiShell>
      <UiShellHeader />
      <Box component="main" sx={{ flex: 1 }}>
        {children}
      </Box>
      <UiShellFooter />
    </StyledUiShell>
  );
};
