import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { navigate } from "gatsby";
import _ from "lodash";
import {
  AppBar,
  ButtonBase,
  Container,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";

import logo from "../../../images/vionex-logo-secondary.svg";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { setUsername } from "../../../store/reducers/auth.reducer";

export const UiShellHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { username } = useSelector((state: any) => state.authReducer);

  const signIn = () => {
    navigate("/builder");
  };

  const signOut = async () => {
    try {
      await Auth.signOut({ global: true });
      dispatch(setUsername(null));
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <AppBar position="static" elevation={0}>
      <Box py={2}>
        <Container>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box display="flex">
                <Box
                  sx={{
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: 2,
                  }}
                >
                  <ButtonBase onClick={() => navigate("/")}>
                    <Box
                      component="img"
                      src={logo}
                      alt={"logo"}
                      sx={{ width: "150px" }}
                    />
                  </ButtonBase>
                </Box>

                <Box ml={2}></Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent={"flex-end"}>
                <Box>
                  {_.isNil(username) ? (
                    <Button onClick={signIn} color="alternate">
                      Sign In
                    </Button>
                  ) : (
                    <Box display="flex">
                      <Box
                        sx={{
                          height: 40,
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          marginRight: 2,
                        }}
                      >
                        <Typography variant="subtitle2">{username}</Typography>
                      </Box>
                      <Box>
                        <IconButton onClick={signOut}>
                          <ExitToAppIcon sx={{ color: "white" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </AppBar>
  );
};
