import React from "react";
import { Box, Typography } from "@mui/material";
import { Amplify } from "aws-amplify";
import { useTheme } from "@aws-amplify/ui-react";

import { Authenticator } from "@aws-amplify/ui-react";
import { Heading, View, Image } from "@aws-amplify/ui-react";

import { AmplifyProvider } from "@aws-amplify/ui-react";
import studioTheme from "../../ui-components/studioTheme.js";

import awsExports from "../../aws-exports";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure(awsExports);

interface AuthenticatedProps {
  children: React.ReactNode;
}

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <Box my={4}>
        <Typography align="center">
          Revolutionize website creation with Vionex's AI and blockchain-powered
          platform. Sign up now to unlock limitless possibilities and create
          extraordinary websites.
        </Typography>
      </Box>
    );
  },
};

export const Authenticated: React.FC<AuthenticatedProps> = ({ children }) => {
  return (
    <AmplifyProvider theme={studioTheme}>
      <Authenticator components={components}>
        <main>{children}</main>
      </Authenticator>
    </AmplifyProvider>
  );
};
