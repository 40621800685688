import { rem } from "polished";

import { Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledUiShellFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    padding: `${rem(16)}`,
  },
}));
