import { rem } from "polished";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledUiShell = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: `${rem(16)}`,
  },
}));
