exports.components = {
  "component---src-pages-builder-business-location-tsx": () => import("./../../../src/pages/builder/business-location.tsx" /* webpackChunkName: "component---src-pages-builder-business-location-tsx" */),
  "component---src-pages-builder-business-name-tsx": () => import("./../../../src/pages/builder/business-name.tsx" /* webpackChunkName: "component---src-pages-builder-business-name-tsx" */),
  "component---src-pages-builder-business-type-tsx": () => import("./../../../src/pages/builder/business-type.tsx" /* webpackChunkName: "component---src-pages-builder-business-type-tsx" */),
  "component---src-pages-builder-finalize-tsx": () => import("./../../../src/pages/builder/finalize.tsx" /* webpackChunkName: "component---src-pages-builder-finalize-tsx" */),
  "component---src-pages-builder-index-tsx": () => import("./../../../src/pages/builder/index.tsx" /* webpackChunkName: "component---src-pages-builder-index-tsx" */),
  "component---src-pages-builder-palette-from-logo-tsx": () => import("./../../../src/pages/builder/palette-from-logo.tsx" /* webpackChunkName: "component---src-pages-builder-palette-from-logo-tsx" */),
  "component---src-pages-builder-template-tsx": () => import("./../../../src/pages/builder/template.tsx" /* webpackChunkName: "component---src-pages-builder-template-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

