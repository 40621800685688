import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import { Authenticated } from "../components/Authenticated";

interface LayoutProps {
  children: ReactNode;
  pageContext: {
    layout: "authenticated" | undefined;
  };
}

const Layout: React.FC<LayoutProps> = ({ children, pageContext }) => {
  if (pageContext.layout === "authenticated") {
    return <Authenticated>{children}</Authenticated>;
  }
  return <>{children}</>;
};

export default Layout;
