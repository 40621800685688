import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTheme } from "@mui/material/styles";
import { lighten, darken } from "polished";

interface Image {
  id: string;
  image: string;
}

interface ThemeConfigState {
  theme: any;
  hexColorValues: HexColorName[];
  builderBusinessName: string | "";
  builderBusinessType: string | "";
  builderBusinessLocation: string | "";
  builderBusinessTemplate: string;
  builderWebsiteCopyRaw: string | "";
  builderWebsiteCopy: any | "";
  builderWebsiteId: any | "";
  builderWebsiteImages: Image[] | "";
}

const generateTheme = (
  prevState: ThemeConfigState,
  newTheme: string[]
): any => {
  const [
    primaryColor,
    secondaryColor,
    tertiaryColor,
    alternateColor,
    accentColor,
  ] = newTheme;

  const theme = createTheme({
    ...prevState.theme,
    palette: {
      // mode: "dark",
      primary: {
        light: lighten(0.1, primaryColor),
        main: primaryColor,
        dark: darken(0.1, primaryColor),
      },
      secondary: {
        light: lighten(0.1, secondaryColor),
        main: secondaryColor,
        dark: darken(0.1, secondaryColor),
      },
      tertiary: {
        light: lighten(0.1, tertiaryColor),
        main: tertiaryColor,
        dark: darken(0.1, tertiaryColor),
      },
      alternate: {
        light: lighten(0.1, alternateColor),
        main: alternateColor,
        dark: darken(0.1, alternateColor),
      },
      accent: {
        light: lighten(0.1, accentColor),
        main: accentColor,
        dark: darken(0.1, accentColor),
      },
    },
  });
  return theme;
};

export const hexColorValuesInit = [
  "#8c8c8c",
  "#8c8c8c",
  "#8c8c8c",
  "#8c8c8c",
  "#8c8c8c",
];

const themeConfig = createSlice({
  name: "themeConfig",
  initialState: {
    theme: {},
    hexColorValues: hexColorValuesInit,
    builderBusinessName: "",
    builderBusinessType: "",
    builderBusinessLocation: "",
    builderBusinessTemplate: "Free",
    builderWebsiteCopyRaw: "",
    builderWebsiteCopy: {},
    builderWebsiteId: "",
    builderWebsiteImages: [],
  } as ThemeConfigState,
  reducers: {
    setTheme: (state, action: PayloadAction<string[]>) => {
      state.theme = generateTheme(state, action.payload);
    },
    setHexColorValues: (state, action: PayloadAction<string[]>) => {
      state.hexColorValues = action.payload;
    },
    setHexColor: (
      state,
      action: PayloadAction<{ index: number; hex: string }>
    ) => {
      const { index, hex } = action.payload;
      state.hexColorValues[index] = hex;
    },
    setBuilderBusinessName: (state, action: PayloadAction<string>) => {
      state.builderBusinessName = action.payload;
    },
    setBuilderBusinessType: (state, action: PayloadAction<string>) => {
      state.builderBusinessType = action.payload;
    },
    setBuilderBusinessLocation: (state, action: PayloadAction<string>) => {
      state.builderBusinessLocation = action.payload;
    },
    setBuilderBusinessTemplate: (state, action: PayloadAction<string>) => {
      state.builderBusinessTemplate = action.payload;
    },
    setBuilderWebsiteCopyRaw: (state, action: PayloadAction<any>) => {
      state.builderWebsiteCopyRaw = action.payload;
    },
    setBuilderWebsiteCopy: (state, action: PayloadAction<any>) => {
      state.builderWebsiteCopy = action.payload;
    },
    setBuilderWebsiteImages: (state, action: PayloadAction<any>) => {
      state.builderWebsiteImages = action.payload;
    },
    setBuilderWebsiteId: (state, action: PayloadAction<any>) => {
      state.builderWebsiteId = action.payload;
    },
    resetBuilder: (state) => {
      state.theme = "";
      state.hexColorValues = hexColorValuesInit;
      state.builderBusinessName = "";
      state.builderBusinessType = "";
      state.builderBusinessLocation = "";
      state.builderBusinessTemplate = "Free";
      state.builderWebsiteCopyRaw = "";
      state.builderWebsiteCopy = "";
      state.builderWebsiteId = "";
    },
    updateHomeLead: (state, action: PayloadAction<string>) => {
      state.builderWebsiteCopy.home.lead = action.payload;
    },
    updateHomeSublead: (state, action: PayloadAction<string>) => {
      state.builderWebsiteCopy.home.sublead = action.payload;
    },
    updateAboutCopy: (state, action: PayloadAction<string>) => {
      state.builderWebsiteCopy.about.copy = action.payload;
    },
    updateServiceTitle: (
      state,
      action: PayloadAction<{ index: number; title: string }>
    ) => {
      const { index, title } = action.payload;
      state.builderWebsiteCopy.services[index].title = title;
    },
    updateServiceLead: (
      state,
      action: PayloadAction<{ index: number; lead: string }>
    ) => {
      const { index, lead } = action.payload;
      state.builderWebsiteCopy.services[index].lead = lead;
    },
  },
});

export const {
  setTheme,
  setHexColorValues,
  setHexColor,
  setBuilderBusinessName,
  setBuilderBusinessType,
  setBuilderBusinessLocation,
  setBuilderBusinessTemplate,
  setBuilderWebsiteCopyRaw,
  setBuilderWebsiteCopy,
  setBuilderWebsiteImages,
  resetBuilder,
  updateHomeLead,
  updateHomeSublead,
  updateAboutCopy,
  updateServiceTitle,
  updateServiceLead,
} = themeConfig.actions;

export default themeConfig.reducer;
