const primaryColor: string = "#31214e";
const secondaryColor: string = "#ee017b";
const tertiaryColor: string = "#a765ce";
const alternate: string = "#27BFD4";
const accent: string = "#D9E021";

const appTitle: string = "Vionex : Branded Blockchain Integrations";
const metaDescription: string =
  "Branded Web3 components to connect your app to the blockchain";
const metaKeywords: string =
  "react, hardhat, solidity, web3, design system, mui";

const googleFonts: string =
  "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap";

const fontFamilies: string =
  "'Source Sans Pro', sans-serif, 'Open Sans', sans-serif";
const headlineFont: string = "'Source Sans Pro', sans-serif";
const bodyFont: string = "'Open Sans', sans-serif";

const headlineFontStyles = {
  fontFamily: headlineFont,
  fontWeight: 800,
  fontStyle: "normal",
  // letterSpacing: "-0.02em",
};

const commonColorStyles = {
  white: "#fff",
  black: "#000",
  grey: "#696a6c",
  lightGrey: "#fafafa",
};

const customColorStyles = {
  wunBlue: {
    main: "#15133f",
  },
};

export {
  googleFonts,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  alternate,
  accent,
  fontFamilies,
  headlineFont,
  headlineFontStyles,
  commonColorStyles,
  bodyFont,
  metaDescription,
  metaKeywords,
  appTitle,
  customColorStyles,
};
