import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import blockchainProvider from "./reducers/blockchainProvider.reducer";
import themeConfig from "./reducers/themeConfig.reducer";
import authReducer from "./reducers/auth.reducer";

export const store = configureStore({
  reducer: {
    blockchainProvider,
    themeConfig,
    authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
