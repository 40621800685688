import { createSlice } from "@reduxjs/toolkit";

export const authReducer = createSlice({
  name: "authReducer",
  initialState: {
    username: null,
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
  },
});

export const { setUsername } = authReducer.actions;

export default authReducer.reducer;
