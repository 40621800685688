import React from "react";
import { Box, Grid, Container, Typography, Link, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { StyledUiShellFooter } from "./UiShellFooter.styles";

export const UiShellFooter: React.FC = () => {
  const theme = useTheme();
  const date = new Date().getFullYear();
  return (
    <StyledUiShellFooter>
      <Divider />
      <Container>
        <Box my={2}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ color: theme.palette.tertiary.main }}
              >{`© ${date} Vionex. All Rights Reserved.`}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent={`flex-end`}>
                <Link
                  href="mailto:hello@vionex.io"
                  sx={{ color: theme.palette.alternate.main }}
                >
                  hello@vionex.io
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </StyledUiShellFooter>
  );
};
