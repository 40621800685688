import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { store } from "../../src/store/store";

import { UiShell } from "../../src/components/UiShell";
import { theme } from "../../src/theme";
import {
  googleFonts,
  metaDescription,
  metaKeywords,
  appTitle,
} from "../../src/theme/theme.config";

export default function TopLayout({ children }) {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <title>{`${appTitle}`}</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href={googleFonts} rel="stylesheet" />
      </Helmet>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <UiShell>{children}</UiShell>
        </ThemeProvider>
      </Provider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
